import React from 'react'
// import { useMediaQuery } from '../hooks/useMediaQuery'
import AnchorLink from 'react-anchor-link-smooth-scroll'

interface DotGroupProps{
  selectedPage: string
  setSelectedPage: React.Dispatch<React.SetStateAction<string>>
}

export const DotGroup: React.FC<DotGroupProps> = ({ selectedPage, setSelectedPage }) => {
  const selectedStyles = `relative bg-yellow before:absolute before:w-6 before:h-6 before:rounded-full 
  before:border-2 before:border-yellow before:top-[-50%] before:left-[-50%]`

  return(
    <div className='flex flex-col gap-6 fixed top-[60%] right-7'>
      <AnchorLink className={`${selectedPage === 'home' ? selectedStyles : 'bg-dark-grey'}
        w-3 h-3 rounded-full transition duration-500 ease-in-out`}
        href='#home'
        onClick={() => setSelectedPage('home')}
      />
      <AnchorLink className={`${selectedPage === 'skills' ? selectedStyles : 'bg-dark-grey'}
        w-3 h-3 rounded-full transition duration-500 ease-in-out`}
        href='#skills'
        onClick={() => setSelectedPage('skills')}
      />
      <AnchorLink className={`${selectedPage === 'projects' ? selectedStyles : 'bg-dark-grey'}
        w-3 h-3 rounded-full transition duration-500 ease-in-out`}
        href='#projects'
        onClick={() => setSelectedPage('projects')}
      />
      <AnchorLink className={`${selectedPage === 'testimonials' ? selectedStyles : 'bg-dark-grey'}
        w-3 h-3 rounded-full transition duration-500 ease-in-out`}
        href='#testimonials'
        onClick={() => setSelectedPage('testimonials')}
      />
      <AnchorLink className={`${selectedPage === 'contact' ? selectedStyles : 'bg-dark-grey'}
        w-3 h-3 rounded-full transition duration-500 ease-in-out`}
        href='#contact'
        onClick={() => setSelectedPage('contact')}
      />
    </div>
  )
}