import React, { useState } from 'react';
import { Project } from '../../scenes/Projects';
import { motion } from "framer-motion";
import { LineGradient } from '../LineGradient';

const tabs = [
  {
      id: 1,
      tabTitle: 'Sistemas',
      title: 'Desevolvimento de Sistesmas',
      content: ''
  },
  {
      id: 2,
      tabTitle: 'Sites',
      title: 'Title 2',
      content: 'Contenido de tab 2.'
  },
  {
      id: 3,
      tabTitle: 'Aplicativos',
      title: 'Title 3',
      content: 'Contenido de tab 3.'
  },
  {
      id: 4,
      tabTitle: 'Big Data',
      title: 'Title 4',
      content: 'Contenido de tab 4.'
  }
];

const container = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
}

type propsTabs = {
  id?: number
  children?: React.ReactNode
  projects: {
    tags: string | undefined;
    tab: number;
    title: string;
    extensionImg: string;
    link: string;
    description: string;
  }[]
}



export const Tabs = ({ id, children, projects, ...rest }: propsTabs) => {
  const [currentTab, setCurrentTab] = useState('1');
  const [visibleProjects, setVisibleProjects] = useState(3);
  const [initialHidden, setInitialHidden] = useState('hidden');


  const handleViewMore = () => {
    setVisibleProjects((prevVisibleProjects) => prevVisibleProjects + 3); // Ajuste conforme necessário
    setInitialHidden("visible")
  };
  //initialHidden = visibleProjects < projects.length ? "hidden" : "visible"
  // if(visibleProjects < projects.length){
  //   setInitialHidden("hidden")
  // }

  console.log("CURRENT TAB", currentTab)
  const handleTabClick = (e: any) => {
    setCurrentTab(e.target.id);
    setInitialHidden("visible")
  }

  return (
    <section id='projects' className='pt-24 pb-24'>
       {/* HEADING */}
       <motion.div
          className="md:w-2/4 mx-auto text-center"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div>
            <p className="font-playfair font-semibold text-4xl">
              <span className="text-red">PRO</span>JECTS
            </p>
            <div className='flex justify-center mt-5'>
              <LineGradient width="w-1/3" />
            </div>
          </div>
          <p className="mt-10 mb-10">
           Alguns dos projetos desenvolvidos ao longo da minha carreira profissional. Além de projetos pessoais, alguns projetos desenvolvidos em parceria com colegas de trabalho e amigos.
          </p>
      </motion.div>

      {/* TABS */}
      <div className='flex justify-center'>
        {tabs.map((tab, i) =>
          <button 
            className={`gap-4 mx-1 mb-8 flex-row justify-center items-center p-2 px-4 rounded-md text-white-800 hover:bg-blue hover:text-gray-900 transition duration-300 ease-in-out ${currentTab === `${tab.id}` ? 'bg-red text-gray-100' : ''}`}
            key={i} 
            id={String(tab.id)} 
            disabled={currentTab === `${tab.id}`} 
            onClick={(handleTabClick)}>
              {tab.tabTitle.toUpperCase()}
          </button>
        )}
      </div>
      {/* PROJECTS */}
      <div className='flex justify-center'>
        <motion.div
          className="sm:grid sm:grid-cols-3"
          initial={initialHidden}
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          variants={container}
        >
          {
            // Filtra os projetos com base no currentTab e verifica se há algum projeto para ser exibido
            projects.slice(0, visibleProjects).filter(project => currentTab === `${project.tab}`).length > 0 ?
            projects.slice(0, visibleProjects).map((project, index) => (
              currentTab === `${project.tab}` &&
              <Project 
                key={index} 
                index={index} 
                title={project.title} 
                extensionImg={project.extensionImg} 
                link={project.link} 
                description={project.description} 
                tags={project.tags}
              />
            ))
            :
            <div className='col-span-3 flex justify-center'>
              <div className='bg-blue p-4 rounded-md gap-4 mx-2 mt-4 text-center text-2xl'>
                <p className='text-orange-200'>UNDER CONSTRUCTION, I WILL ADD THE PROJECTS SOON</p>
              </div>
            </div>
          } 
        </motion.div>
      </div>

      {/* VIEW MORE */}
      <div className='flex w-full h-full justify-center text-center pt-24'>
      {visibleProjects < projects.length && (
        <button
          className="p-5 bg-blue w-1/2 font-playfair font-semibold text-2xl text-deep-blue hover:bg-red hover:text-white transition duration-500 rounded-md"
          type="button"
          onClick={handleViewMore}
        >
          VIEW MORE
        </button>
      )}
      </div>
    </section>
    
  )

} 
// ${tab.id}