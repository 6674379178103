import React from 'react'
import { FaLinkedin, FaGithub, FaInstagram } from 'react-icons/fa'; // Importe os ícones que deseja usar
// import { useMediaQuery } from '../hooks/useMediaQuery'

interface NavbarProps{
  selectedPage: string
  setSelectedPage: React.Dispatch<React.SetStateAction<string>>
  children?: React.ReactNode
  page?: string
  isTopOfPage?: boolean
  isBottomOfPage?: boolean
}

// interface LinkProps{
//   selectedPage: string
//   setSelectedPage: React.Dispatch<React.SetStateAction<string>>
//   page: string
// }
export const Footer: React.FC<NavbarProps> = ({ isTopOfPage, isBottomOfPage, selectedPage, page, setSelectedPage, ...children }) => {
  // const isAboveSmallScreens = useMediaQuery("(min-width: 768px)")
  // const lowerCasePage = page ? page!.toLowerCase() : 'home'
  const bottomBackground = isBottomOfPage ? '#010026' : '#010026'//'bg-red'
  const descricaoResumida = "Líder técnico na Ubicua, especialista em cloud computing e programação. Bacharel em análise de sistemas, MBA em gestão de projetos e estudante de Big Data.";
  return (
    <footer className={`z-40 w-full fixed bottom-0 ${bottomBackground} py-2`}>
      {isBottomOfPage && isBottomOfPage !== undefined ? (
        <div className='flex w-full justify-around px-6'>
          <span className='text-white text-sm'>© 1988 - ???? 😁 Copyright. Todos os direitos reservados.</span>
          <span className='text-white text-sm'>Desenvolvido com ❤️ por xarlysouza</span>
        </div>
      ) : (
        <div className='flex flex-col md:flex-row w-full justify-around px-6'>
          {/* Coluna de Descrição */}
          <div className='flex flex-col text-white text-sm md:w-2/2'>
            <p>{descricaoResumida}</p>
            {/* Ícones de Redes Sociais */}
            <div className='flex justify-center items-center gap-4 mt-4'>
              <a href="https://www.linkedin.com/in/xarlys-luiz/" target="_blank" rel="noopener noreferrer"><FaLinkedin size={24} /></a>
              <a href="https://github.com/xarlys" target="_blank" rel="noopener noreferrer"><FaGithub size={24} /></a>
              <a href="https://www.instagram.com/renataexarlys" target="_blank" rel="noopener noreferrer"><FaInstagram size={24} /></a>
            </div>
            
          </div>

          {/* Coluna de Links de Menu */}
          {/* <div className='flex justify-around gap-2 font-opensans text-sm font-semibold md:w-1/2'>
            <Link page="Home" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
            <Link page="Skills" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
            <Link page="Projects" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
            <Link page="Testimonials" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
            <Link page="Contact" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
          </div> */}
        </div>
      )}
    </footer>
  );
}


// const Link: React.FC<LinkProps> = ({ page, selectedPage, setSelectedPage }) => {
//   const lowerCasePage = page.toLowerCase()

//   return (
//     <AnchorLink className={`${selectedPage === lowerCasePage ? 'text-yellow': ''}
//       hover:text-yellow transition duration-500 ease-in-out`}
//       href={`#${lowerCasePage}`}
//       onClick={() => setSelectedPage(lowerCasePage)}
//     >
//       {page}
//     </AnchorLink>
//   )
// }
