import React from 'react'
import { motion } from "framer-motion";
import { LineGradient } from '../components/LineGradient'

export const Testimonials: React.FC = () => {
    return (
      <section id="testimonials" className="pt-32 pb-16 ">
        {/* HEADING */}
        <motion.div
          className="md:w-1/3 text-center md:text-left"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-4xl mb-5 text-red">
            TESTIMONIALS
          </p>
          <LineGradient width="mx-auto w-2/5" />
          <p className="mt-10">
          O que algumas pessoas têm a dizer sobre mim e o meu trabalho através de depoimentos autênticos e experiências compartilhadas.
          </p>
        </motion.div>
  
        {/* TESTIMONIALS */}
        <div className="md:flex md:justify-between gap-8 ">
          <motion.div
            className="mx-auto relative bg-blue max-w-[400px] h-[380px] flex flex-col justify-end px-16 py-4 mt-48 shadow-lg
            before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2 before:content-leonardo rounded-md"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.6 }}
            variants={{
              hidden: { opacity: 0, scale: 0.8 },
              visible: { opacity: 1, scale: 1 },
            }}
          >
            <p className="font-playfair text-6xl">“</p>
            <p className="text-justify text-sm text-black">
            Xarlys é um líder excepcional, habilidoso em fornecer instruções claras e inspirar resultados extraordinários. Sua expertise abrange não apenas a liderança eficaz, mas também a notável habilidade de educar, criando um ambiente propício para o crescimento. Sua destreza em enfrentar desafios com criatividade e inovação, aliada a uma abordagem envolvente, torna-o um verdadeiro especialista, além de proporcionar um ambiente de trabalho leve e colaborativo.
            </p>
          </motion.div>

          <motion.div
            className="mx-auto relative bg-red max-w-[400px] h-[380px] flex flex-col justify-end px-16 py-4 mt-48
              before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2 before:content-daiane rounded-md"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.4, duration: 0.6 }}
            variants={{
              hidden: { opacity: 0, scale: 0.8 },
              visible: { opacity: 1, scale: 1 },
            }}
          >
            <p className="font-playfair text-6xl">“</p>
            <p className="text-justify text-xs text-black">
              Xarlys é um líder excelente e um mentor incrível. Digo isso porque iniciei na minha primeira oportunidade na área de tecnologia sendo orientada por ele, e isso fez com que o meu desenvolvimento como pessoa e desenvolvedora evoluísse ainda mais. Ele sempre passa as orientações com muita clareza, explicando os conteúdos de forma simples e o porquê de serem feitas de determinada forma. Lidera com maestria, desenvolvendo o melhor de seus desenvolvedores, compartilhando soluções, ideias e inovação. Traz uma leveza para o âmbito profissional, deixando o clima entre a equipe bem confortável, humorado e feliz.
            </p>
          </motion.div>
  
          <motion.div
            className="mx-auto relative bg-yellow max-w-[400px] h-[380px] flex flex-col justify-end px-16 py-4 mt-48
              before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2 before:content-yuri rounded-md"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.6 }}
            variants={{
              hidden: { opacity: 0, scale: 0.8 },
              visible: { opacity: 1, scale: 1 },
            }}
          >
            <p className="font-playfair text-6xl">“</p>
            <p className="text-justify text-sm text-black">
              My Friend Xarlovisk é um líder técnico talentoso e mentor dedicado, transmitindo conhecimento de maneira clara e acessível. Sua maestria nas funções, habilidade de construir equipes e criar um ambiente colaborativo tornam-no um colega inspirador e inestimável para a equipe. Com uma capacidade excepcional de unir pessoas, motivá-las e manter a equipe coesa.
            </p>
          </motion.div>
  
          
        </div>
      </section>
    );
}

export default Testimonials