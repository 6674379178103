const projects = [
  {
    tab: 1,
    title: "Rede Conecta Itaú",
    extensionImg: "svg",
    link: "https://redeconecta.com.br/",
    description: "O Sistema Rede Conecta foi desenvolvido para atender às demandas do Banco Itaú, proporcionando um eficaz canal de atendimento aos clientes que buscam soluções para seus negócios. Criado com foco na otimização da experiência do usuário, o sistema oferece respostas ágeis e inovadoras.",
    tags: '#CSHARP #IONIC #ANGULAR #SQLSERVER #AZURE #DEVOPS'
  },
  {
    tab: 1,
    title: "Mulheres Brilhantes",
    extensionImg: "png",
    link: "https://apps.apple.com/br/app/clube-mb/id6465251058",
    description: "O Aplicativo Clube Mulheres Brilhantes foi projetado para mulheres determinadas a impulsionar seus negócios e causar impacto. Destinado a empreendedoras ávidas por crescimento e destaque, o aplicativo oferece uma plataforma para construção de networks, estímulo à inovação e fomento ao engajamento.",
    tags: '#REACT-NATIVE #NODEJS #MYSQL #REDIS #STRIPE #STYLED-COMPONENTS #AWS #DEVOPS'
  },
  {
    tab: 1,
    title: "Condoyou",
    extensionImg: "png",
    link: "https://condoyou.com.br/",
    description: "Condoyou, um sistema de gestão condominial foi desenvolvido para atender gestoras, condomínios, condôminos e síndicos, oferecendo diversas funcionalidades para simplificar o cotidiano de moradores e funcionários. Com uma abordagem abrangente, o sistema proporciona uma experiência integrada, tornando a administração condominial mais eficiente e acessível.",
    tags: '#REACT #NODEJS #MYSQL #REDIS #STRIPE #STYLED-COMPONENTS #AWS #DEVOPS'
  },
  {
    tab: 1,
    title: "Ipersonality",
    extensionImg: "png",
    link: "https://ipersonality.org/",
    description: "O iPersonality é uma inovadora plataforma, a evolução dos últimos 100 anos na área de autoconhecimento e mapeamento cognitivo comportamental.",
    tags: '#REACT #NODEJS #MYSQL #REDIS #STRIPE #TAILWIND #LEGATHUM #AWS #DEVOPS'
  },
  {
    tab: 1,
    title: "Motovia",
    extensionImg: "png",
    link: "https://motoviagv.com.br/",
    description: "Empresa atuante no mercado de motocicletas desde 2004, oferecendo produtos de qualidade e confiança para todos os seus clientes. Atualmente estão nas atividades da empresa o comércio de motocicletas, peças e acessórios, além do comércio de automóveis.",
    tags: '#PHP #MYSQL #JAVASCRIPT #HTML #CSS #BOOTSTRAP #JQUERY #LINUX #APACHE #NGINX'
  },
  // {
  //   title: "Motovia",
  //   extensionImg: "png",
  //   link: "https://motoviagv.com.br/",
  //   description: "Empresa atuante no mercado de motocicletas desde 2004, oferecendo produtos de qualidade e confiança para todos os seus clientes. Atualmente estão nas atividades da empresa o comércio de motocicletas, peças e acessórios, além do comércio de automóveis.",
  // },
  // {
  //   title: "Motovia",
  //   extensionImg: "png",
  //   link: "https://motoviagv.com.br/",
  //   description: "Empresa atuante no mercado de motocicletas desde 2004, oferecendo produtos de qualidade e confiança para todos os seus clientes. Atualmente estão nas atividades da empresa o comércio de motocicletas, peças e acessórios, além do comércio de automóveis.",
  // },
  // {
  //   title: "Motovia",
  //   extensionImg: "png",
  //   link: "https://motoviagv.com.br/",
  //   description: "Empresa atuante no mercado de motocicletas desde 2004, oferecendo produtos de qualidade e confiança para todos os seus clientes. Atualmente estão nas atividades da empresa o comércio de motocicletas, peças e acessórios, além do comércio de automóveis.",
  // },

];

export default projects;
