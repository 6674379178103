import React from 'react';

interface LineGradientProps {
  width?: string
}

export const LineGradient: React.FC<LineGradientProps> = ({ width = "w-full" }) => { 
  return (
   <div className={`h-0.5 ${width} bg-gradient-rainblue`} />
  )
};