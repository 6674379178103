import React from 'react'
import { motion } from "framer-motion";
import projects from '../data/projects';
import { Tabs } from '../components/Tabs';

// const container = {
//   hidden: {},
//   visible: {
//     transition: {
//       staggerChildren: 0.2,
//     },
//   },
// }

const projectVariant = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
}

interface ProjectProps  {
  index: number
  title: string
  extensionImg: string 
  description?: string
  link?: string
  tags?: string
}

export const Project = ({ index, title, extensionImg, description, link, tags }: ProjectProps) => {
  const overlayStyles = `absolute h-full w-full opacity-0 hover:opacity-90 transition duration-500
  bg-grey z-30 flex flex-col justify-center items-center text-start rounded-md px-8 py-4  text-deep-blue`
  const projectTitle = title.split(" ").join("-").toLowerCase();
  const extension = extensionImg || extensionImg !== undefined ? extensionImg : 'jpeg'
  return (
    <motion.div variants={projectVariant} className='relative m-2 mt-4'>
      <div className={overlayStyles}>
        <p className='text-2xl font-playfair'>{title}</p>
        <p className='mt-6'>
          {description}
        </p> 
        {link && (
          <a 
            className='bg-red p-2 mt-2 text-white rounded-md' 
            target="_blank" 
            rel="noreferrer" 
            title={link} 
            href={link}
          >
            {link}
          </a>
        )}
        <span className='flex justify-center mt-2 mb-4'>
          <p className='text-opaque-black text-xs font-bold'>{ tags }</p>   
        </span>
      </div>
      <div className={`flex w-full justify-center items-center rounded-md text-center ${index % 2 === 0 ? 'bg-blue' : 'bg-red'}`}>
        <img
        src={`../assets/${projectTitle}.${extension}`} //.jpeg
        alt={projectTitle} 
        className='flex justify-center text-center items-center p-4 rounded-lg
        min-w-[400px] min-h-[400px] max-w-[400px] max-h-[400px] text-2xl font-playfair font-semibold '
        />
        
      </div>
      
      
    </motion.div>
  )
}
// className="w-full h-full object-cover"
export const Projects: React.FC = () => {
 
  return (
    <div>
      <Tabs projects={projects} />
    </div> 
  )
}


