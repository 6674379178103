import React, {useEffect, useState} from 'react'
import { useMediaQuery } from './hooks/useMediaQuery'
import { Navbar } from './scenes/Navbar'
import { DotGroup } from './scenes/DotGroup'
import { Landing } from './scenes/Landing'
import { LineGradient } from './components/LineGradient'
import { MySkills } from './scenes/MySkills'
import { Projects } from './scenes/Projects'
import Testimonials from './scenes/Testimonials'
import { Contact } from './scenes/Contact'
import { Footer } from './scenes/Footer'

const App: React.FC = () => {
  const [selectedPage, setSelectedPage] = useState('home')
  const isAboveMediumScreens = useMediaQuery('(min-width: 1060px)')
  const [isTopOfPage, setIsTopOfPage] = useState(true)
  const [isBottomOfPage, setIsBottomOfPage] = useState(true)

  useEffect(() => {
    console.log("window.scrollY", window.scrollY)
    const handleScroll = () => {
      if (window.scrollY === 0) setIsTopOfPage(true)
      if (window.scrollY !== 0) setIsTopOfPage(false)
      if (window.scrollY === 0) setIsBottomOfPage(true)
      if (window.scrollY >= 3200) setIsBottomOfPage(false)

    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className="app bg-deep-blue">
    <Navbar
      isTopOfPage={isTopOfPage}
      selectedPage={selectedPage}
      setSelectedPage={setSelectedPage}
    />
    <div className="w-5/6 mx-auto md:h-full">
      {isAboveMediumScreens && (
        <DotGroup
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      )}
      <Landing setSelectedPage={setSelectedPage} />
      {/* <motion.div
        margin="0 0 -200px 0"
        amount="all"
        onViewportEnter={() => setSelectedPage("home")}
      >
      </motion.div> */}
    </div>
    <LineGradient />
    <div className="w-5/6 mx-auto md:h-full">
      <MySkills />
    </div>
    <LineGradient />
    <div className="w-5/6 mx-auto">
      <Projects />
    </div>
    <LineGradient />
    <div className="w-5/6 mx-auto md:h-full">
      <Testimonials />
    </div>
    <div className="w-5/6 mx-auto">
      <Contact />
    </div>
    <Footer 
      isTopOfPage={isTopOfPage}
      isBottomOfPage={isBottomOfPage}
      selectedPage={selectedPage}
      setSelectedPage={setSelectedPage}
    />


    {/* <div className="w-5/6 mx-auto md:h-full ">
      <motion.div
        margin="0 0 -200px 0"
        amount="all"
        onViewportEnter={() => setSelectedPage("skills")}
      >
        <MySkills />
      </motion.div>
    </div>
    <LineGradient />
    <div className="w-5/6 mx-auto">
      <motion.div
        margin="0 0 -200px 0"
        amount="all"
        onViewportEnter={() => setSelectedPage("projects")}
      >
        <Projects />
      </motion.div>
    </div>
    <LineGradient />
    <div className="w-5/6 mx-auto md:h-full">
      <motion.div
        margin="0 0 -200px 0"
        amount="all"
        onViewportEnter={() => setSelectedPage("testimonials")}
      >
        <Testimonials />
      </motion.div>
    </div>
    <LineGradient />
    <div className="w-5/6 mx-auto md:h-full">
      <motion.div
        margin="0 0 -200px 0"
        amount="all"
        onViewportEnter={() => setSelectedPage("contact")}
      >
        <Contact />
      </motion.div>
    </div>
    <Footer /> */}
  </div>
  )
}

export { App }