import React from 'react';
import { motion } from "framer-motion";
import { useMediaQuery } from '../hooks/useMediaQuery';
import { LineGradient } from '../components/LineGradient';

export const MySkills: React.FC = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");

  return (
    <section id="skills" className="pt-6 pb-24">
      {/* HEADER AND IMAGE SECTION */}
      <div className="md:flex md:justify-between md:gap-16 mt-28">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-4xl mb-5">
            MY SOFT <span className="text-red">SKILLS</span>
          </p>
          <LineGradient width="w-1/3" />
          <p className="mt-10 mb-7">
          Busco sempre inovar e trazer soluções criativas e eficientes para os desafios que enfrento. Acredito que a liderança é uma forma de influenciar positivamente as pessoas ao meu redor, motivando-as a se desenvolverem pessoal e profissionalmente.
          </p>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (
            <div
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10
              before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]"
            >
              <img
                alt="skills"
                className="z-10"
                src="assets/skills-image.png"
              />
            </div>
          ) : (
            <img alt="skills" className="z-10" src="assets/skills-image.png" />
          )}
        </div>
      </div>

      {/* SKILLS */}
      <div className="md:flex md:justify-between mt-1 gap-32">
        {/* EXPERIENCE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">01</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Comprometimento
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1] rounded-sm" />
          </div>
          <p className="mt-3 text-sm text-start">
          Ao longo de mais de 16 anos no desenvolvimento profissional, destaco-me pelo comprometimento integral com o sucesso do cliente.  A priorização da satisfação do cliente é o alicerce que norteia meu trabalho, consolidando-me como um profissional comprometido com resultados duradouros.
          </p>
        </motion.div>

        {/* INNOVATIVE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">02</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Inovação
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-red absolute right-0 top-0 z-[-1] rounded-sm" />
          </div>
          <p className="mt-3 text-sm text-start">
            Minha trajetória na Ubicua é marcada por uma abordagem inovadora na automação de processos para o cliente Itaú. A utilização de linguagens de ponta, demonstra minha disposição em explorar tecnologias avançadas para aprimorar soluções em cloud computing. A busca incessante por novas perspectivas e soluções inovadoras é um traço distintivo que permeia meu compromisso com a tecnológica.
          </p>
        </motion.div>
        {/* IMAGINATIVE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">03</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Proatividade
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-yellow absolute right-0 top-0 z-[-1] rounded-sm" />
          </div>
          <p className="mt-3 text-sm text-start">
            Coordenar a equipe de desenvolvimento enquanto participo ativamente da programação, aliado à minha postura proativa, define minha abordagem dinâmica. Antecipo desafios, identifico oportunidades e promovo uma cultura de constante aprendizado. Além disso, meu empenho em aprimoramento contínuo, reflete a proatividade que me impulsiona a superar expectativas e contribuir significativamente para o sucesso dos projetos.
          </p>
        </motion.div>
      </div>
    </section>
  )
}