import React from 'react'
import { useMediaQuery } from '../hooks/useMediaQuery'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { motion } from 'framer-motion'
import { SocialMediaIcons } from '../components/SocialMediaIcons'

interface LandingProps{
  selectedPage?: string
  setSelectedPage: React.Dispatch<React.SetStateAction<string>>
}

export const Landing: React.FC<LandingProps> = ({ selectedPage, setSelectedPage }) => {
  const isAboveMediumScreens = useMediaQuery('(min-width: 1060px)')
  
  return(
    <section
      id="home"
      className="md:flex md:justify-between md:items-center gap-16 md:h-full py-10"
    >
      {/* IMAGE SECTION */}
      <div className="basis-3/5 z-10 mt-16 md:mt-32 flex justify-center md:order-2">
        {isAboveMediumScreens ? (
          <div
            className="relative z-0 ml-20 before:absolute before:-top-20 before:-left-20 before:rounded-t-[400px]
            before:w-full before:max-w-[400px] md:before:max-w-[600px] before:h-full before:border-2 before:border-blue before:z-[-1]"
          >
            <img
              alt="profile"
              className="rounded-t-[400px] hover:filter hover:saturate-200 transition duration-500 z-10 w-full max-w-[400px] md:max-w-[600px]"
              src="assets/profile-image-x.jpeg"
            />
          </div>
        ) : (
          <img
            alt="profile"
            className="z-10 w-full max-w-[400px] md:max-w-[600px] rounded-t-[400px]"
            src="assets/profile-image-x.jpeg"
          />
        )}
      </div>

      {/* MAIN TEXT */}
      <div className="z-30 basis-2/5 mt-12 md:mt-32">
        {/* HEADINGS */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="text-6xl font-playfair z-10 text-center md:text-start">
            Xarlys {""}
            <span
              className="xs:relative xs:text-deep-blue xs:font-semibold z-20 xs:before:content-brush
              before:absolute before:-left-[25px] before:-top-[70px] before:z-[-1]"
            >
              Souza
            </span>
          </p>

          <p className="mt-10 mb-7 text-sm text-center  md:text-justify">
          Com mais de 14 anos de experiência na área de tecnologia, sou um líder técnico na Ubicua, uma empresa de soluções em cloud computing. Sou responsável pelo atendimento ao cliente <b>Itaú,</b> dando sustentação ao aplicativo Rede Conecta, que automatiza processos e solicitações dos executivos de conta. Além de coordenar a equipe de desenvolvimento, também participo ativamente da programação, utilizando linguagens como NodeJS, ReactJS, Angular, C#, .NET, NextJS c/ TypeScript.
          <br />
          Sou bacharel em análise de sistemas pela Univale, MBA em gestão de projetos pela Fundação Getulio Vargas, e atualmente cursando MBA em Big Data pela Descomplica. Tenho uma paixão por aprender e compartilhar conhecimentos.
          <br />
          <br />
          <hr />
          Soft Skills: Proatividade | Dedicação | Comprometimento | Flexibilidade | Colaboração | Empatia | Inovação | Responsabilidade
          {/* Sou bacharelado em análise de sistemas pela Univale, possuo MBA em gestão de projetos pela Fundação Getúlio Vargas, e atualmente estou cursando meu MBA em Big Data. Tenho uma vasta experiência construída ao longo de mais de 16 anos de carreira, com uma combinação diversa de competências, que unem experiências em empresas de divergentes frentes, na área de tecnologia. */}
          </p>
        </motion.div>

        {/* CALL TO ACTIONS */}
        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <AnchorLink
            className="bg-gradient-rainblue text-deep-blue rounded-sm py-3 px-7 font-semibold
              hover:bg-blue hover:text-white transition duration-500"
            onClick={() => setSelectedPage("contact")}
            href="#contact"
          >
            Contate-me
          </AnchorLink>
          <AnchorLink
            className="rounded-r-sm bg-gradient-rainblue py-0.5 pr-0.5"
            onClick={() => setSelectedPage("contact")}
            href="#contact"
          >
            <div className="bg-deep-blue hover:text-red transition duration-500 w-full h-full flex items-center justify-center px-10 font-playfair">
              Vamos conversar.
            </div>
          </AnchorLink>
        </motion.div>

        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <SocialMediaIcons />
        </motion.div>
      </div>
    </section>
  )
}