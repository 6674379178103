import React, { useState } from 'react'
import { useMediaQuery } from '../hooks/useMediaQuery'
import AnchorLink from 'react-anchor-link-smooth-scroll'

interface NavbarProps{
  selectedPage: string
  setSelectedPage: React.Dispatch<React.SetStateAction<string>>
  children?: React.ReactNode
  page?: string
  isTopOfPage?: boolean
}

interface LinkProps{
  selectedPage: string
  setSelectedPage: React.Dispatch<React.SetStateAction<string>>
  page: string
}

const Link: React.FC<LinkProps> = ({ page, selectedPage, setSelectedPage }) => {
  const lowerCasePage = page.toLowerCase()

  return (
    <AnchorLink className={`${selectedPage === lowerCasePage ? 'text-yellow': ''}
      hover:text-yellow transition duration-500 ease-in-out`}
      href={`#${lowerCasePage}`}
      onClick={() => setSelectedPage(lowerCasePage)}
    >
      {page}
    </AnchorLink>
  )
}

export const Navbar: React.FC<NavbarProps> = ({ isTopOfPage, selectedPage, setSelectedPage, ...children }) => {
  const [ isMenuToggled, setIsMenuToggled ] = useState(false)
  const isAboveSmallScreens = useMediaQuery("(min-width: 768px)")
  const navbarBackground = isTopOfPage ? 'bg-transparent' : 'bg-red'

  return (
    <nav className={`${navbarBackground} z-40 w-full fixed top-0 py-6`}>
      <div className="flex justify-between items-center mx-auto w-5/6">
        <h4 className='font-playfair text-3xl font-bold'>XS</h4>

        {/* DESKTOP NAV */}
        {isAboveSmallScreens ? (
          <div className='flex justify-between gap-12 font-opensans text-lg font-semibold'>
           <Link page="Home" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
           <Link page="Skills" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
           <Link page="Projects" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
           <Link page="Testimonials" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
           <Link page="Contact" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
          </div>
        ) : (
          <button
          className='rounded-full p-2 bg-red'
          onClick={() => { setIsMenuToggled(!isMenuToggled) }}
          >
            <img alt='menu-icon' src="../assets/menu-icon.svg" />
          </button>
        ) }

        {/* MOBILE MENU POPUP */}
        { !isAboveSmallScreens && isMenuToggled && (
          <div className='fixed right-0 bottom-0 h-full bg-blue w-[300px]'>
            {/* close icon */}
            <div className='flex justify-end p-12'>
              <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                <img alt='close-icon' src="../assets/menu-icon.svg" />
              </button>
            </div>

            {/* menu items */}
            <div className='flex flex-col gap-10 ml-[33%] text-2xl text-deep-blue'>
              <Link page="Home" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
              <Link page="Skills" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
              <Link page="Projects" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
              <Link page="Testimonials" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
              <Link page="Contact" selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
            </div>
          </div>
        )}
      </div>
    </nav>
  )
}

